<template>
  <div>
    <template>
      <v-row justify="center">
        <v-dialog persistent v-model="dialog" width="950px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="showPreview"
              small
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
            >
              PREVIEW
            </v-btn>
          </template>
          <v-card id="printPage" flat>
            <v-card-title class="primary justify-content-between py-4 title">
              <div class="mx-auto">
                <span class="text-h6 white--text text-uppercase">{{
                  payslipTitle
                }}</span>
              </div>
              <div>
                <v-icon class="text--right" @click="closeDialog"
                  >mdi-close-thick</v-icon
                >
              </div>
            </v-card-title>

            <v-card-text>
              <div class="payslip-date py-2 custom-padding">
                <span class="payslipStyle">
                  <!-- Payslip for {{getDate}} -->
                  Payslip for
                  {{ retrieveMonth(data.month) + ", " + data.year }}
                </span>
              </div>
              <div
                class="payslip-date d-flex justify-space-between py-2 px-13 custom-padding"
              >
                <label
                  ><strong class="payslipStyle"
                    >SSNIT Office Complex</strong
                  ></label
                >
                <label
                  ><strong class="payslipStyle"
                    >27 Ama Akroma Street</strong
                  ></label
                >
                <label
                  ><strong class="payslipStyle">Takoradi - Ghana</strong></label
                >
              </div>
            </v-card-text>

            <div>
              <!-- employee info -->
              <div class="w-100 px-16 payslipStyle">
                <v-row class="px-3">
                  <v-col cols="5" class="d-flex justify-content-between">
                    <strong>Staff Name:</strong>
                    <span>{{
                      data?.employee?.displayName
                        ? data?.employee?.displayName?.toUpperCase()
                        : fullName?.toUpperCase()
                    }}</span>
                  </v-col>
                  <v-col cols="2"></v-col>
                  <v-col cols="5" class="d-flex justify-content-between">
                    <strong>Staff ID:</strong>
                    <span>{{ payrollId(data) }}</span>
                  </v-col>
                </v-row>

                <v-row class="my-0 px-3">
                  <v-col cols="5" class="d-flex justify-content-between py-1">
                    <strong>Department: </strong>
                    <span>{{
                      data?.employee?.department
                        ? data?.employee?.department
                        : " - "
                    }}</span>
                  </v-col>
                  <v-col cols="2"></v-col>
                  <v-col cols="5" class="d-flex justify-content-between py-1">
                    <strong>Job Description:</strong>
                    <span>{{
                      data?.employee?.employeePosition?.toUpperCase()
                    }}</span>
                  </v-col>
                </v-row>

                <v-row class="my-0 px-3">
                  <v-col cols="5" class="d-flex justify-content-between py-1">
                    <strong>Email:</strong>
                    <span>{{ data?.employee?.workEmail }}</span>
                  </v-col>
                  <v-col cols="2"></v-col>
                  <v-col cols="5" class="d-flex justify-content-between py-1">
                    <strong>Annual Basic Salary:</strong>
                    <span>{{
                      (data?.employee?.basicSalary * 12).toFixed(2)
                    }}</span>
                  </v-col>
                </v-row>

                <v-row class="my-0 px-3">
                  <v-col cols="5" class="d-flex justify-content-between py-1">
                    <strong>Bank Name:</strong>
                    <span>{{
                      data?.employee?.employeeBankDetails?.bankName
                    }}</span>
                  </v-col>
                  <v-col cols="2"></v-col>
                  <v-col cols="5" class="d-flex justify-content-between py-1">
                    <strong>Branch:</strong>
                    <span>{{
                      data?.employee?.employeeBankDetails?.branchName
                    }}</span>
                  </v-col>
                </v-row>

                <v-row class="my-0 px-3">
                  <v-col cols="5" class="d-flex justify-content-between py-1">
                    <strong>Account Number:</strong>
                    <span>{{
                      data?.employee?.employeeBankDetails?.accountNumber
                    }}</span>
                  </v-col>
                  <v-col cols="2"></v-col>
                  <v-col cols="5" class="d-flex justify-content-between py-1">
                    <strong>SSNIT Number:</strong>
                    <span>{{
                      data?.employee?.employeeIdentification?.customSSN
                    }}</span>
                  </v-col>
                </v-row>
              </div>

              <!-- earnings -->
              <v-row class="px-16 mt-12">
                <v-col cols="12" sm="12" md="12" class="px-7 payslipStyle">
                  <label class="custom-padding"
                    ><strong>Earnings</strong></label
                  >
                  <table aria-labelledby="earnings">
                    <tbody>
                      <tr v-if="data.__typename !== 'NspPayroll'">
                        <th
                          scope="row"
                          class="text-left py-3 px-5 custom-padding custom-left"
                        >
                          Basic Salary
                        </th>
                        <td
                          class="text-right py-3 px-5 custom-padding custom-right"
                        >
                          GH¢
                          {{ formatFigure(data?.employee?.basicSalary) }}
                        </td>
                      </tr>
                      <tr v-if="data.monthlyNspAllowance">
                        <th
                          scope="row"
                          class="text-left py-3 px-5 custom-padding custom-left"
                        >
                          NSP Allowance
                        </th>
                        <td
                          class="text-right py-3 px-5 custom-padding custom-right"
                        >
                          GH¢
                          {{ formatFigure(Number(data?.monthlyNspAllowance)) }}
                        </td>
                      </tr>
                      <tr v-if="data.monthlyAmalitechAllowance">
                        <th
                          scope="row"
                          class="text-left py-3 px-5 custom-padding custom-left"
                        >
                          Amalitech Allowance
                        </th>
                        <td
                          class="text-right py-3 px-5 custom-padding custom-right"
                        >
                          GH¢
                          {{
                            formatFigure(
                              Number(data?.monthlyAmalitechAllowance)
                            )
                          }}
                        </td>
                      </tr>
                      <tr v-if="data.backPay > 0">
                        <th
                          scope="row"
                          class="text-left py-3 px-5 custom-padding custom-left"
                        >
                          Back Pay
                        </th>
                        <td
                          class="text-right py-3 px-5 custom-padding custom-right"
                        >
                          GH¢
                          {{ formatFigure(Number(data?.employee?.backPay)) }}
                        </td>
                      </tr>

                      <tr
                        v-for="(allowance, i) in data.allowances"
                        :key="`allowancex_${i}`"
                      >
                        <th
                          scope="row"
                          class="text-left py-3 px-5 custom-padding custom-left"
                        >
                          {{ allowance?.description }}
                        </th>
                        <td
                          class="align-content-end py-3 px-5 custom-padding custom-right"
                        >
                          GH¢
                          {{ formatFigure(allowance?.monthlyAllowance) }}
                        </td>
                      </tr>

                      <tr
                        v-for="(bonus, i) in data.bonuses"
                        :key="`bonus_${i}`"
                      >
                        <th
                          v-if="
                            bonus.description !== 'Team Performance' &&
                            bonus.description !== 'KPI Bonus'
                          "
                          scope="row"
                          class="text-left py-3 px-5 custom-padding custom-left"
                        >
                          {{ bonus?.description }}
                        </th>
                        <td
                          v-if="
                            bonus.description !== 'Team Performance' &&
                            bonus.description !== 'KPI Bonus'
                          "
                          class="align-content-end py-3 px-5 custom-padding custom-right"
                        >
                          GH¢
                          {{ formatFigure(bonus?.monthlyBonus) }}
                        </td>
                      </tr>
                      <tr v-if="data.__typename !== 'NspPayroll'">
                        <th
                          scope="row"
                          class="py-3 px-5 custom-padding custom-left"
                        >
                          Total Earnings
                        </th>
                        <td
                          class="text-right py-3 px-5 custom-padding custom-right"
                        >
                          <strong
                            >GH¢ {{ formatFigure(totalEarnings()) }}</strong
                          >
                        </td>
                      </tr>
                      <tr v-if="data.monthlyAllowancePayable">
                        <th
                          scope="row"
                          class="py-3 px-5 custom-padding custom-left"
                        >
                          Total Earnings
                        </th>
                        <td
                          class="text-right py-3 px-5 custom-padding custom-right"
                        >
                          <strong
                            >GH¢
                            {{
                              formatFigure(
                                data?.monthlyNspAllowance +
                                  data?.monthlyAmalitechAllowance
                              )
                            }}</strong
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </v-col>
              </v-row>
              <v-row class="px-16 mt-12">
                <v-col cols="12" sm="12" md="12" class="px-7 payslipStyle">
                  <label><strong>Deductions</strong></label>
                  <table aria-labelledby="deductions">
                    <tbody>
                      <tr v-if="data.employeeSSF">
                        <th
                          scope="row"
                          class="text-left py-3 px-5 custom-padding"
                        >
                          Employee 5.5% SSF
                        </th>
                        <td class="text-right py-3 px-5 custom-padding">
                          GH¢ {{ formatFigure(data?.employeeSSF) }}
                        </td>
                      </tr>
                      <tr v-if="data.paye">
                        <th
                          scope="row"
                          class="text-left py-3 px-5 custom-padding"
                        >
                          P.A.Y.E.
                        </th>
                        <td class="text-right py-3 px-5 custom-padding">
                          GH¢
                          {{ formatFigure(Number(data?.paye)) }}
                        </td>
                      </tr>
                      <tr v-if="data.totalTaxOnBonus > 0">
                        <th
                          scope="row"
                          class="text-left py-3 px-5 custom-padding"
                        >
                          Tax On Bonuses
                        </th>
                        <td class="text-right py-3 px-5 custom-padding">
                          GH¢
                          {{ formatFigure(Number(data?.totalTaxOnBonus)) }}
                        </td>
                      </tr>
                      <tr v-if="data.employeeTierThreePre > 0">
                        <th
                          scope="row"
                          class="text-left py-3 px-5 custom-padding"
                        >
                          Employee Tier 3
                        </th>
                        <td class="text-right py-3 px-5 custom-padding">
                          GH¢
                          {{ formatFigure(Number(data?.employeeTierThreePre)) }}
                        </td>
                      </tr>
                      <tr
                        v-for="(deduction, i) in data.deductions"
                        :key="`deductionx_${i}`"
                      >
                        <th
                          scope="row"
                          class="text-left py-3 px-5 custom-padding custom-left"
                        >
                          {{ deduction.description }}
                        </th>
                        <td
                          class="align-content-end py-3 px-5 custom-padding custom-right"
                        >
                          GH¢
                          {{ formatFigure(deduction?.monthlyDeduction) }}
                        </td>
                      </tr>
                      <tr v-if="data.monthlyAllowanceTax">
                        <th
                          scope="row"
                          class="text-left py-3 px-5 custom-padding"
                        >
                          Tax on allowances
                        </th>
                        <td class="text-right py-3 px-5 custom-padding">
                          GH¢
                          {{ formatFigure(data?.monthlyAllowanceTax) }}
                        </td>
                      </tr>
                      <tr>
                        <th
                          scope="row"
                          class="text-left py-3 px-5 custom-padding"
                        >
                          Total Deductions
                        </th>
                        <td class="text--right py-3 px-5 custom-padding">
                          <strong
                            >GH¢
                            {{
                              data.totalDeduction
                                ? formatFigure(data?.totalDeduction)
                                : formatFigure(data?.monthlyAllowanceTax)
                            }}</strong
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </v-col>
              </v-row>
              <v-row
                class="px-16 mt-12"
                v-for="bonus in data.bonuses"
                :key="bonus"
              >
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                  class="px-7 payslipStyle"
                  v-if="
                    bonus.description === 'Team Performance' ||
                    bonus.description === 'KPI Bonus'
                  "
                >
                  <label class="custom-padding">
                    <strong>Other Bonuses</strong>
                  </label>
                  <table aria-labelledby="earnings">
                    <tbody>
                      <tr
                        v-for="(bonus, i) in data.bonuses"
                        :key="`bonus_${i}`"
                      >
                        <th
                          v-if="
                            bonus.description === 'Team Performance' ||
                            bonus.description === 'KPI Bonus'
                          "
                          scope="row"
                          class="text-left py-3 px-5 custom-padding custom-left"
                        >
                          {{ bonus?.description }}
                        </th>

                        <td
                          v-if="
                            bonus.description === 'Team Performance' ||
                            bonus.description === 'KPI Bonus'
                          "
                          class="align-content-end py-3 px-5 custom-padding custom-right"
                        >
                          GH¢
                          {{ formatFigure(bonus?.monthlyBonus) }}
                        </td>
                      </tr>
                      <tr v-for="bonus in data.bonuses" :key="bonus">
                        <th
                          v-if="
                            bonus.description === 'Team Performance' ||
                            bonus.description === 'KPI Bonus'
                          "
                          scope="row"
                          class="text-left py-3 px-5 custom-padding custom-left"
                        >
                          Net Bonus
                        </th>
                        <td
                          v-if="bonus?.description === 'Team Performance'"
                          class="align-content-end py-3 px-5 custom-padding custom-right"
                        >
                          GH¢
                          {{
                            formatFigure(
                              bonus?.monthlyBonus -
                                (data?.teamPerformance !== null
                                  ? data?.teamPerformance?.tpTax
                                  : 0)
                            )
                          }}
                        </td>
                        <td
                          v-if="bonus?.description === 'KPI Bonus'"
                          class="align-content-end py-3 px-5 custom-padding custom-right"
                        >
                          GH¢
                          {{
                            formatFigure(
                              bonus?.monthlyBonus -
                                (data?.kpiBonus !== null
                                  ? data?.kpiBonus?.kpiTax
                                  : 0)
                            )
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </v-col>
              </v-row>
              <v-card-text>
                <div
                  class="table-border d-flex justify-space-between mt-10 py-2 px-13 custom-padding custom-margin"
                >
                  <label
                    ><strong class="payslipStyle">Net Salary</strong></label
                  >
                  <label
                    ><strong class="payslipStyle"
                      >GH¢
                      {{
                        data?.netSalary
                          ? formatFigure(data?.netSalary)
                          : formatFigure(data?.monthlyAllowancePayable)
                      }}</strong
                    ></label
                  >
                </div>
              </v-card-text>
            </div>
            <v-card-actions class="justify-center">
              <v-btn @click="closeDialog" color="secondary" depressed>
                Cancel
              </v-btn>
              <v-btn
                color="primary"
                @click="closeDialog"
                depressed
                v-print="'#printPage'"
              >
                print slip
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
  </div>
</template>
<script>
import moment from "moment";
import { DOWNLOAD_EMPLOYEE_PAYROLL } from "../../services/graphql/mutations/payroll_mutations";
import fileDownload from "js-file-download";
export default {
  props: {
    showPreview: {
      default: true,
      type: Boolean,
    },
    dialog: {
      default: false,
      type: Boolean,
    },
    data: {
      type: Object,
      default: null,
    },
    closeDialog: {
      type: Function,
    },
  },
  data() {
    return {
      // dialog: false,
    };
  },

  computed: {
    getDate() {
      const date = new Date();
      return moment(date).format("MMMM, YYYY");
    },
    payslipTitle() {
      let prefix = this.data?.employee?.employeeId.slice(0, 2);
      let title = "";
      if (prefix === "SC" || prefix === "OP") {
        title = "Amalitech Ghana Limited";
      } else {
        title = "Amalitech Training";
      }
      return title.toUpperCase();
    },
    /*get the payslip for the current month*/
    getCurrentPayslip() {
      if (this.payroll && this.payroll?.length > 0) {
        return (
          this.payroll.filter((payslip) => {
            return (
              payslip.month === this.currentMonthPayslip &&
              payslip.year === this.currentYearPayslip
            );
          }) || []
        );
      } else {
        return [];
      }
    },
    currentMonthPayslip() {
      return new Date().getMonth() + 1;
    },
    currentYearPayslip() {
      return new Date().getFullYear();
    },

    fullName() {
      return (
        this.data?.employee?.firstName +
        " " +
        (this.data?.employee?.middleName === null
          ? " "
          : this.data?.employee?.middleName) +
        " " +
        this.data?.employee?.lastName
      );
    },
  },

  methods: {
    formatFigure(item) {
      return new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(item);
    },

    payrollId(item) {
      let tmp = item?.employee?.employeeId.slice(0, 3);
      let scTmp = item?.employee?.employeeId.slice(0, 2);
      let newItem = { ...item.employee };
      let nspItem = { ...item };
      let id = "";
      const pattern = /_\d+_\d{4}$/;
      if (scTmp === "SC") {
        id = newItem?.employeeId;
      } else if (tmp === "TRN") {
        id = newItem?.employeeId;
      } else if (tmp === "NSP") {
        id = nspItem?.payrollId.replace(pattern, "");
      } else {
        id = newItem?.employeeId;
      }
      return id;
    },

    retrieveMonth(monthNumber) {
      switch (monthNumber) {
        case 1:
          return "January";
        case 2:
          return "February";
        case 3:
          return "March";
        case 4:
          return "April";
        case 5:
          return "May";
        case 6:
          return "June";
        case 7:
          return "July";
        case 8:
          return "August";
        case 9:
          return "September";
        case 10:
          return "October";
        case 11:
          return "November";
        case 12:
          return "December";
        default:
          return "Unspecified Month";
      }
    },

    async payslipDownload() {
      this.$apollo
        .mutate({
          mutation: DOWNLOAD_EMPLOYEE_PAYROLL,
          variables: {
            employeeID: this.data.employeeId,
            month: this.data.month,
            year: this.data.year,
            responseType: "blob",
            headers: { Accept: "multipart/form-data" },
          },
        })
        .then((response) => {
          const date = moment(this.data.date).format("MMM YYYY");
          const title = `Payslip_${this.data.firstName}_${this.data.lastName}_${date}.pdf`;

          fileDownload(response.data.getEmployeePayslip, title);
        })
        .catch(async (error) => {
          console.log("error message: ", error);
        });
    },

    totalEarnings() {
      const basicSalary = this.data?.employee?.basicSalary || 0;
      const backPay = this.data?.employee?.backPay || 0;
      const totalAllowance = this.calculateTotal(
        this.data?.allowances,
        "monthlyAllowance"
      );
      const totalBonus = this.calculateTotal(
        this.data?.bonuses?.filter(
          (bonus) =>
            bonus.description !== "Team Performance" &&
            bonus.description !== "KPI Bonus"
        ),
        "monthlyBonus"
      );

      return basicSalary + backPay + totalAllowance + totalBonus;
    },
    calculateTotal(arr, key) {
      return Array.isArray(arr)
        ? arr.reduce((total, item) => total + (item[key] ? item[key] : 0), 0)
        : 0;
    },
    totalDeductions() {
      let totalDeduction = 0;
      for (let deduction of this.data.deductions) {
        totalDeduction += deduction.monthlyDeduction;
      }
      return (
        this.data?.employeeSSF +
        this.data?.paye +
        this.data?.totalTaxOnBonus +
        totalDeduction
      );
    },
  },
};
</script>
<style scoped>
.payslipStyle {
  font-family: "Times New Roman", Times, serif;
  color: black;
}

.payslip-date {
  border-bottom: 3px solid #cf4f1f;
  font-weight: bold;
  text-align: center;
}

.table-border {
  border-bottom: 3px solid #cf4f1f;
  border-top: 3px solid #cf4f1f;
  font-size: 1.2rem;
  padding: 0.2rem;
}

.title {
  color: #fff;
  background-color: #cf4f1f;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: 1.8rem;
  justify-content: center;
}

.custom-right {
  align-content: right;
  padding-right: -1rem;
}

.custom-left {
  align-content: left;
}

.custom-padding {
  padding: 0.6rem;
}

.custom-margin-top {
  margin-top: -0.9rem;
}

table {
  border-collapse: collapse;
  width: 100%;
}

tr {
  border: 2px solid #dadada;
}

th {
  text-align: left;
}

td {
  text-align: right;
}
</style>
