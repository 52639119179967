<template>
  <div>
    <div>
      <div>
        <v-container grid-list-md text-xs-center>
          <v-layout row wrap>
            <v-flex
              md3
              sm12
              lg3
              v-for="item in employees"
              :key="item.employeeId"
            >
              <v-hover>
                <template v-slot:default="{ hover }">
                  <v-card class="mx-auto thumbnail" max-width="20vw">
                    <p class="primary py-2 mb-n1" style="text-align: center">
                      <span class="white--text">{{
                        item?.employee?.displayName
                      }}</span>
                    </p>

                    <div style="text-align: center" class="payslip-month">
                      <span>
                        Payslip for
                        <strong>{{
                          retrieveMonth(item?.month) + ", " + item?.year
                        }}</strong>
                      </span>
                    </div>

                    <div>
                      <v-row class="px-0">
                        <v-col cols="6" sm="6" md="6" class="pl-5">
                          <div
                            class="d-flex justify-space-between payslipStyle"
                          >
                            <label><span>Staff Name: </span></label>
                            <div>
                              <p>{{ item?.employee?.displayName }}</p>
                            </div>
                          </div>
                          <div
                            class="d-flex justify-space-between payslipStyle"
                          >
                            <label><span>Department: </span></label>
                            <div className="d-flex ">
                              <p>
                                {{ setDepartment(item) }}
                              </p>
                            </div>
                          </div>
                          <div
                            class="d-flex justify-space-between payslipStyle"
                          >
                            <label><span>Email: </span></label>
                            <div className="d-flex ">
                              <p>
                                {{ item?.employee?.workEmail }}
                              </p>
                            </div>
                          </div>
                        </v-col>
                        <v-col cols="6" sm="6" md="6" class="pr-5">
                          <div
                            class="d-flex justify-space-between payslipStyle"
                          >
                            <label><span>Staff ID</span></label>
                            <div>
                              <p>
                                {{ payrollId(item) }}
                              </p>
                            </div>
                          </div>

                          <div
                            class="d-flex justify-space-between payslipStyle"
                          >
                            <label><span>Job Description</span></label>
                            <div>
                              <p>
                                {{ item?.employee?.employeePosition }}
                              </p>
                            </div>
                          </div>

                          <div
                            v-if="item.__typename !== 'NspPayroll'"
                            class="d-flex justify-space-between payslipStyle"
                          >
                            <label><span>Basic Salary</span></label>
                            <div>
                              <p>
                                {{ formatFigure(item?.employee?.basicSalary) }}
                              </p>
                            </div>
                          </div>
                          <div
                            v-if="item.__typename === 'NspPayroll'"
                            class="d-flex justify-space-between payslipStyle"
                          >
                            <label><span>Monthly Allowance</span></label>
                            <div>
                              <p>
                                {{
                                  formatFigure(item?.monthlyAllowancePayable)
                                }}
                              </p>
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                      <v-row class="px-2 mt-1">
                        <v-col cols="12" sm="12" md="12">
                          <label class="payslip-earnings"
                            ><strong>Earnings</strong></label
                          >
                          <table v-if="item.grossSalary" aria-label="">
                            <th scope="col"></th>
                            <tbody class="payslipStyle">
                              <tr>
                                <td class="text-left py-2 px-5">
                                  Gross Salary
                                </td>
                                <td class="text-right py-2 px-5">
                                  {{ formatFigure(item?.grossSalary) }}
                                </td>
                              </tr>
                              <tr>
                                <td class="text-left py-2 px-5">Net Salary</td>
                                <td class="text-right py-2 px-5">
                                  {{ formatFigure(item?.netSalary) }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <table v-else aria-label="">
                            <th scope="col"></th>
                            <tbody class="payslipStyle">
                              <tr>
                                <td class="text-left py-2 px-5">
                                  Total Earnings
                                </td>
                                <td class="text-right py-2 px-5">
                                  {{
                                    formatFigure(item?.employee?.basicSalary)
                                  }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </v-col>
                      </v-row>
                    </div>
                    <v-fade-transition>
                      <v-overlay
                        v-if="hover"
                        absolute
                        color="#036358"
                        z-index="10000"
                      >
                        <v-btn
                          color="primary"
                          @click.prevent="showDialog(item)"
                        >
                          Preview
                        </v-btn>
                      </v-overlay>
                    </v-fade-transition>
                  </v-card>
                </template>
              </v-hover>
            </v-flex>
          </v-layout>
        </v-container>
      </div>
    </div>
    <div class="text-center pt-2">
      <v-pagination
        v-model="page"
        :length="totalPages"
        @input="paginatePage()"
        circle
      ></v-pagination>
    </div>
    <dialog-pay-slip
      :closeDialog="closeDialog"
      :dialog="dialog"
      :showPreview="false"
      :data="item"
    ></dialog-pay-slip>
  </div>
</template>

<script>
import moment from "moment";
import DialogPaySlip from "../../views/reports/DialogPaySlip";
import validation from "../../services/validation";

export default {
  name: "PaySlipThumbnail",
  components: { DialogPaySlip },
  props: {
    employees: {
      type: Array,
      default: null,
    },
    totalPages: {
      type: Number,
      default: null,
    },
  },
  data: () => ({
    ...validation,
    overlay: false,
    dialog: false,
    isloading: false,
    item: {},
    year: null,
    month: null,
    date: "",

    menu: false,
  }),

  computed: {
    datas() {
      return this.$store.state.report.bankAdviceReport.content;
    },
    data() {
      let today = new Date();
      let date =
        today.getFullYear() + "-" + ("0" + (today.getMonth() + 1)).slice(-2);
      return {
        yearMonth: date,
      };
    },
    totalPagesd() {
      return this.$store.getters["report/totalPages"];
    },
    page: {
      get() {
        return this.$store.getters["report/currentPage"];
      },
      set(val) {
        return this.$store.commit("report/SET_CURRENT_PAGE", val);
      },
    },
  },

  methods: {
    formatFigure(item) {
      return new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(item);
    },

    retrieveMonth(monthNumber) {
      switch (monthNumber) {
        case 1:
          return "January";
        case 2:
          return "February";
        case 3:
          return "March";
        case 4:
          return "April";
        case 5:
          return "May";
        case 6:
          return "June";
        case 7:
          return "July";
        case 8:
          return "August";
        case 9:
          return "September";
        case 10:
          return "October";
        case 11:
          return "November";
        case 12:
          return "December";
        default:
          return "Unspecified Month";
      }
    },

    payrollId(item) {
      let tmp = item?.employee?.employeeId.slice(0, 3);
      let scTmp = item?.employee?.employeeId.slice(0, 2);
      let newItem = { ...item.employee };
      let nspItem = { ...item };
      let id = "";
      const pattern = /_\d+_\d{4}$/;
      if (scTmp === "SC") {
        id = newItem?.employeeId;
      } else if (tmp === "TRN") {
        id = newItem?.employeeId;
      } else if (tmp === "NSP") {
        id = nspItem?.payrollId.replace(pattern, "");
      } else {
        id = newItem?.employeeId;
      }
      return id;
    },

    setDepartment(item) {
      let tmp = item?.employee?.employeeId.slice(0, 3);
      let scTmp = item?.employee?.employeeId.slice(0, 2);
      let newItem = { ...item.employee };

      if (scTmp === "SC") {
        newItem.department = "SERVICE CENTER";
      } else if (tmp === "TRN") {
        newItem.department = "TRAINING CENTER";
      } else if (tmp === "NSP") {
        newItem.department = "NATIONAL SERVICE";
      } else {
        newItem.department = "OPERATIONS";
      }
      return newItem.department;
    },
    closeDialog() {
      this.dialog = false;
    },
    showDialog(data) {
      this.item = data;
      this.dialog = true;
    },
    currentYear() {
      return new Date().getFullYear();
    },
    async initialize() {
      await this.$store.dispatch("report/fetchBankAdviceReport", {
        page: 1,
        data: this.data,
      });
    },

    currentMonth() {
      return ("0" + (new Date().getMonth() + 1)).slice(-2);
    },
    getDateMonth() {
      const year = this.currentYear();
      const month = this.currentMonth();
      return { yearMonth: `${year}-${month}` };
    },
    getDate(date) {
      const month = moment(date, "YYYY-M").format("MMMM");
      const year = moment(date, "YYYY-M").format("YYYY");
      return `${month}  ${year}`;
    },
    async handlefilter() {
      this.isloading = true;
      const data = {
        yearMonth: `${this.date}`,
      };
      await this.$store.dispatch("report/fetchBankAdviceReport", {
        page: this.page,
        data: data,
      });
      this.isloading = false;
    },
    async paginatePage() {
      this.isloading = true;
      this.scrollToTop();
      await this.$store.dispatch("report/fetchBankAdviceReport", {
        page: this.page,
        data: this.data,
      });
      this.isloading = false;
    },
    scrollToTop() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>
<style scoped>
.thumbnail {
  height: 38vh;
  overflow: hidden;
}
.payslip-month {
  font-family: "Times New Roman", Times, serif;
  font-size: 14px;
  padding: 0.6rem;
}
.payslip-earnings {
  font-family: "Times New Roman", Times, serif;
  font-size: 12px;
}
.payslipStyle {
  font-family: "Times New Roman", Times, serif;
  font-size: 12px;
  font-weight: lighter;
  color: black;
}
.payslip-date {
  border-bottom: 3px solid #cf4f1f;
  font-weight: bold;
  text-align: center;
}
.table-border {
  border-bottom: 3px solid #cf4f1f;
  border-top: 3px solid #cf4f1f;
  font-size: 1.2rem;
}

table {
  border-collapse: collapse;
  width: 100%;
}
tr {
  border: 2px solid #dadada;
}
.col-3 {
  padding: 10 !important;
  min-width: 100%;
}
</style>
